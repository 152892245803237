.parent-wrapper {
    height:100%;
    width:100%;
}
.parent {
    display: flex;
    font-size: 0;
    flex-wrap:wrap;
    margin:-10px 0 0 -10px;
}
.child {
    display: inline-block;
    margin:10px 0 0 10px;
    flex-grow: 1;   
    width: calc(100% * (1/3) - 10px - 1px);
    transition: 0.3s;
}
.grow-description{
    display: none;
    position: absolute;
    background-color: white;
    padding: 10px;
    font-size: 13.5px;
    bottom: 0;
}
.z-hover-effect{
    transition: transform 1s ease;
}

.z-hover-effect:hover {
    transform: scale(0.95);
}

.z-hover-effect:hover .grow-description {
    display: block;
    width: 100%;
    transition: transform 1s ease;
}
.card-img-top{
    height: 250px;
}

.text-big{
    font-size: 20px
}


@media all and (max-width: 760px) {
    .child{
        width: 100%;
    }
}