.flex-info{
    justify-content: space-between;
}
hr{
    background-color: #c30017;
    height: 1px;
}
.j-content{
    justify-content: space-between;
    align-items: center;
}

.border{
    border: 1px solid #eee;
}

p.no-mb{
    margin-bottom: 0;
}
.flex-occupy{
    flex: 1;
}

.bg-fluid{
    width: 100%;
    background-size: cover;
    height: 100%;

}

.flex-item:first-child {
    margin-right: 10px;
}

.flex-item:last-child {
    margin-left: 10px;
}

.flex-item:not(.flex-item:first-child, .flex-item:last-child) {
    margin-right: 10px;
    margin-left: 10px;
}
  
  

@media all and (max-width: 760px) {
    .m-mb-3{
        margin-bottom: 1rem !important;
    }
    .flex-info{
        flex-wrap: wrap;
    }
    .flex-item{
        width: 100%;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .flex-occupy{
        flex: auto;
    }
    .card:first-child {
        margin-bottom: 10px;
    }

    .card:last-child {
        margin-top: 10px;
    }

    .card:not(.card:first-child, .card:last-child) {
        margin-bottom: 10px;
        margin-top: 10px;
    }
  
}