header{
  position: fixed;
  top: 0;
  z-index: 20;
  width: 100%;
}

a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

.m-mr-3{
  margin-right: 1rem;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

@media screen and (max-width: 768px) {

  .m-mr-3{
    margin-right: 0;
  }
}

.logo-size{
  width: 150px;
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}
.ng-white{
  background-color: white;
  z-index: 2;
}

.navbar-nav .active{
  background-color:  #c30017;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.navbar-nav a.active{
  color: #ffffff !important;
}