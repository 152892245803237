#footer {
    width: 100%;
    height: 70px;
    background-color: #840010;
    
  }
  .my-flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .footer-block {
    margin: 20px 0;
  }

